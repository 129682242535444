// Mood Board Color
export const BG_PRIMARY = 'rgba(242,242,242,1)'; // Warna Chreme Muda
export const BG_SECONDARY = 'rgba(69,69,69,1)'; // Warna Chreme Yang Lebih Tua
export const BG_ALTERNATIVE = 'rgba(110,109,110,1)'; // Warna Ketiga Coklat Tua
// Text Color
export const TEXT_PRIMARY = 'rgba(29,29,29,1)'; // Warna Hitam Body
export const TEXT_SECONDARY = 'rgba(242,242,242,1)'; // Warana Coklat Muda
export const TEXT_ALTERNATIVE = 'rgba(69,69,69,1)'; // Alternative Color
export const TEXT_BUTTON = 'rgba(254,254,254,1)';

// Music & Navigation Color
export const NAVIGATION_COLOR = BG_ALTERNATIVE; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya

// Default Button Color
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};

export const DEFAULT_BUTTON_PROPS = {};
