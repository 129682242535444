/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `BiankaScript, cursive;`,
  body: `'Philosopher', sans-serif;`,
  body_lv2: `'Philosopher', sans-serif;`,
  name: `BiankaScript, cursive;`,
  fullName: `'Philosopher', sans-serif;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    textAlign: 'center',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    textAlign: 'center',
  },
  body_lv2: {
    fontFamily: 'body_lv2',
    fontSize: 'body_lv2',
    fontWeight: 'body_lv2',
    fontStyle: 'var(--fontItalic-body_lv2)',
    textAlign: 'center',
  },
  nameProps: {
    fontFamily: 'name',
    fontSize: 'name',
    fontWeight: 'name',
    fontStyle: 'var(--fontItalic-name)',
  },
  fullNameProps: {
    fontFamily: 'fullName',
    fontSize: 'fullName',
    fontWeight: 'fullName',
    fontStyle: 'var(--fontItalic-fullName)',
  },
};
