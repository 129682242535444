import { extendTheme } from '@chakra-ui/react';
import {
  BG_ALTERNATIVE,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  TEXT_BUTTON,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Arima',
    body: 'Ysabeau Infant',
    body_lv2: 'Ysabeau Infant',
    name: 'Arima',
    fullName: 'Ysabeau Infant',
    // for generator purpose
    jost: `'Jost', sans-serif;`,
    delafield: `'Mrs Saint Delafield', cursive;`,
  },
  fontSizes: {
    heading: '42px',
    body: '16px',
    body_lv2: '14px',
    name: '48px',
    fullName: '20px',
  },
  fontWeights: {
    heading: 400,
    body: 500,
    body_lv2: 500,
    name: 400,
    fullName: 500,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-body': '',
        '--fontItalic-body_lv2': '',
        '--fontItalic-name': '',
        '--fontItalic-fullName': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    alternativeColorText: TEXT_ALTERNATIVE,
    btnColorText: TEXT_BUTTON,
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
      },
    },
  },
};

export default extendTheme(theme);
